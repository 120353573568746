<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6" md="4"><SelectFranquia hide-details label="* Franquia:" v-model="registro.id_franquia" /></v-col>
                            <v-col cols="12" sm="6" md="4"><SelectChamadoCategoria hide-details label="* Categoria:" v-model="registro.id_categoria" /></v-col>
                            <v-col cols="12" md="4"><v-text-field hide-details label="* Assunto:" v-model="registro.chamado" /></v-col>
                            <v-col cols="12"><v-textarea hide-details label="* Descrição:" v-model="registro.descricao" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'
import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue";
import SelectChamadoCategoria from "@/Views/Estrutura/Form/SelectChamadoCategoria.vue";

export default {
    name: "ChamadoForm",
    components: {SelectChamadoCategoria, SelectFranquia},
    props : ['id_aluno'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'usuarioSessao'])
    },
    methods : {
        salvar() {
            axios.post(`${this.apiUrl}chamado/novo`, this.registro).then( (res) => {
                this.erro = null
                this.$router.push({path : '/chamado/gerenciar/' + res.data})
            }).catch(e => {
                this.erro = this.erroFormatar(e)
            })
        }
    }
}
</script>

<style scoped>

</style>
